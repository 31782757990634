/* ==========================================================================
Sections
   ========================================================================== */

.section {
	&--theme-pink {
		background: $pink;
		&.section {
			&--intro {
				color: $white;
				.heading-base {
					color: $white;
				}
				.btn-base {
					color: $white;
				}
				.breadcrumbs, .breadcrumbs a {
					color: $blue-dark;
				}
				
			}
		}
	}
	&--bg-pink-pattern {
		background: $pink url(../images/bg-pattern.jpg) no-repeat center;
		background-size: cover;
	}
	&--theme-aqua-light {
		background: $aqua-light;
	}
	&--theme-blue-dark {
		background: $blue-dark;
		color: $white;
		.breadcrumbs {
			a {
				color: $white;
			}
		}
		/* .heading {
			color: $pink;
		} */
		h4 {
			color: $pink;
		}
	}
	&--theme-gray {
		background: $gray-light;
	}
	&--theme-aqua {
		background: $aqua;
	}

	
	&--offsets-base {

		padding-top: 50px;
		padding-bottom: 50px;
		
		/* .heading {
			margin-bottom: 70px;
		} */

		@include breakpoint(lg) {
			padding-top: 70px;
			padding-bottom: 70px;
		}
	}
	&--offsets-small {
		padding-top: 40px;
		padding-bottom: 40px;
		@include breakpoint(lg) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}

	&--testimonials {
		.heading {
			margin-bottom: 30px;
			@include breakpoint(md) {
				margin-bottom: 50px;
			}
			@include breakpoint(xl) {
				margin-bottom: 70px;
			}
		}
	}

	&--services {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-top: 65px;
			padding-bottom: 65px;
		}
		@include breakpoint(xl) {
			padding-bottom: 130px;
		}
		.heading {
			margin-bottom: 40px;
			@include breakpoint(lg) {
				margin-bottom: 70px;
			}
			@include breakpoint(xl) {
				margin-bottom: 105px;
			}
		}
	}

	&--photo {
		line-height: 0;
		height: 300px;
		@include breakpoint(md) {
			height: 400px;
		}
		@include breakpoint(xl) {
			height: 612px;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&--team {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-top: 70px;
			padding-bottom: 85px;
		}
		.heading-base {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	&--news {
		padding-top: 50px;
		padding-bottom: 40px;
		@include breakpoint(lg) {
			padding-top: 65px;
		}
		.heading {
			margin-bottom: 30px;
			@include breakpoint(md) {
				margin-bottom: 60px;
			}
		}
	}

	&--accreditations {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(md) {
			padding-top: 65px;
		}
		.heading {
			margin-bottom: 30px;
			@include breakpoint(md) {
				margin-bottom: 50px;
			}
			@include breakpoint(lg) {
				margin-bottom: 100px;
			}
		}
	}
	
	&--intro {
		padding-top: 130px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-bottom: 85px;
		}
		.heading-base {
			margin-bottom: 15px;
		}
		.breadcrumbs {
			margin-bottom: 30px;
		}
	}

	&--info {
		padding-top: 65px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-bottom: 85px;
		}
	}
	
	&--support {
		background: $pink url(../images/bg-pattern.jpg) no-repeat center -600px;
		background-size: cover;
		text-align: center;
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-top: 85px;
			padding-bottom: 90px;
		}
		.heading-base {
			margin-bottom: 25px;
		}
	}

	&--family-content {
		padding-top: 50px;
		padding-bottom: 30px;
		@include breakpoint(lg) {
			padding-top: 75px;
			padding-bottom: 80px;
		}
		.heading {
			margin-bottom: 40px;
			@include breakpoint(lg) {
				margin-bottom: 55px;
			}
		}
	}

	&--family-more {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-top: 95px;
			padding-bottom: 95px;
		}
	}

	&--expect {
		padding-top: 55px;
		padding-bottom: 50px;
		@include breakpoint(xl) {
			padding-bottom: 80px;
		}
		@include breakpoint(xl) {
			padding-bottom: 169px;
		}
		.heading {
			margin-bottom: 50px;
			@include breakpoint(xl) {
				margin-bottom: 80px;
			}
		}
	}

	&--how-we-work {
		padding-top: 50px;
		padding-bottom: 36px;
		@include breakpoint(lg) {
			padding-top: 67px;
			padding-bottom: 95px;
		}
		.heading {
			@include breakpoint(lg) {
				margin-bottom: 55px;
			}
		}
	}

	&--personal-top {
		padding-top: 180px;
	}

	&--top {
		padding-top: 180px;
		padding-bottom: 50px;
		.breadcrumbs {
			margin-bottom: 30px;
		}
	}

	&--contact {
		padding-top: 50px;
		padding-bottom: 50px;
		@include breakpoint(lg) {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	&--contact-main {
		.heading {
			margin-bottom: 50px;
		}
	}

	&--contact-form {
		.heading {
			margin-bottom: 10px;
		}
	}



}