$white: #ffffff;
$pink: #F05A64;
$blue-dark: #08172B;
$aqua: #6BB9C1;
$aqua-light: #CCE6E6;
$black: #000000;
$gray-light: #E7E6E2;
$gray: #343944;

.color {
	&-white {
		color: $white;
	}
	&-aqua {
		color: $aqua;
	}
}