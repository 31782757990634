/* ==========================================================================
// Layout
   ========================================================================== */


:root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
}


html {
    font-size: 62.5%;
}

html, body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    

    -webkit-text-decoration-skip: objects;
    text-decoration-skip: objects;

    text-rendering: optimizeLegibility;

    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;


    overflow-x: hidden;
    overflow-y: auto;

    will-change: scroll-position;

}

.app {
    position: relative;
    min-height: 100%;
    overflow: hidden;
    width: 100%;
    min-width: 320px;

    background: #fff;
    color: $blue-dark;

    display: flex;
    flex-direction: column;
}

.container-fluid {
    width: 100%;
    padding: 0 10px;
    max-width: 1980px;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1410px;
    @include breakpoint(lg) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

