/* ==========================================================================
// Form
   ========================================================================== */


.is-error {
    border-color: #8A0502;
}

.is-success {
    border-color: #159E24;
}

// -----

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	-webkit-appearance: none;
	border-radius: 0;
	background-color: transparent;
}

button {
	cursor: pointer;
	border: 0;
	outline: none;
}

input {
	vertical-align: middle;
	outline: none;
	border: 0;
	&[type="submit"], &[type="button"] {
		cursor: pointer;
		-webkit-appearance: none;
	}
}

textarea {
	outline: none;
	resize: none;
	overflow: auto;
}

:invalid {
	box-shadow: none;
}

// -----

.ui-input {
	display: block;
	width: 100%;
	background: $gray-light;
	height: 52px;
	border: 1px solid transparent;
	font-size: 17px;
	font-weight: 300;
	padding: 5px 30px;
	margin-bottom: 25px;
	font-family: $fontBase;
	color: $blue-dark;

	-webkit-appearance: none;
	transition: 0.25s;
	
	&:focus {
		border-color: rgba(0,0,0,.2);
	}

	&::placeholder {
		color: $blue-dark;
	}
}

///

.ui-checkbox {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: $pink;
	&__icon {
		flex-shrink: 0;
		margin-right: 10px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $pink;
		transition: .3s;
		svg {
			opacity: 0;
			visibility: hidden;
			fill: $white;
			max-width: 70%;
			max-height: 70%;
		}
	}
	&__text {
		font-weight: 300;
	}
	input{
		&:checked {
			&+.ui-checkbox__icon {
				background: $pink;
				svg {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

