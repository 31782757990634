/* ==========================================================================
// Main
   ========================================================================== */

.main {
	flex: 1 0 auto;
	width: 100%;
}

.section-main {
	&__slide {
		color: $white;
		background: $blue-dark url(../images/main-bg-pattern.png) no-repeat center 0;
		background-position: right 0;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		@include breakpoint(lg) {
			min-height: 612px;
		}
		&-img {
			position: absolute;
			right: 0;
			pointer-events: none;
			top: 0;
			height: 100%;
			max-width: none;
			@include breakpointMax(md) {
				height: auto;
				max-width: 100%;
				bottom: 0;
				top: auto;
			}
		}
		.container {
			position: relative;
			z-index: 10;
			padding-top: 100px;
			@include breakpoint(md) {
				padding-top: 130px;
			}
		}
	}
	.heading-main {
		text-shadow: 1px 1px 1px $blue-dark;
	}
	p {
		font-weight: 300;
		font-size: 17px;
		line-height: 24px;
		text-shadow: 1px 1px 1px $blue-dark;
	}
}

///

.btn-more-wrapper {
	margin-top: 42px;
}

///

.menu-categorie {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 20px;
	@include breakpoint(lg) {
		padding-top: 8px;
	}
	&>li {
		position: relative;
		&:not(:last-child) {
			padding-bottom: 10px;
		}
		&:before {
			content: "";
			position: absolute;
			left: -40px;
			top: 8px;
			width: 17.5px;
			height: 17.5px;
			border-radius: 100%;
			background: $pink;
			transition: .3s;
			opacity: 0;
			visibility: hidden;
		}
		&:hover {
			@media (hover: hover) and (pointer: fine) {
				&:before {
					opacity: 1;
					visibility: visible;
				}
				.heading-base {	
					color: $pink;
				}
			}
			// .menu-categorie {
			// 	&__content {
			// 		display: block;
			// 	}
			// }
		}
		&.active {
			&:before {
				opacity: 1;
				visibility: visible;
			}
			.heading-base {
				color: $pink;
			}
		}
		.heading-base {
			cursor: pointer;
		}
		a.heading-base {
			text-decoration: none;
			color: $blue-dark;
			&:hover {
				color: $pink;
			}
			@include breakpointMax(xl) {
				font-size: 30px;
			}
		}
		p {
			margin-bottom: 10px;
		}
	}
	&__content {
		display: none;
		padding-top: 10px;
		padding-bottom: 15px;
		@include breakpoint(lg) {
			padding-bottom: 30px;
		}
	}
}

.services-img {
	@include breakpointMax(lg) {
		max-width: 300px;
		
	}
}

///

.testimonial-info {
	line-height: 1;
	font-weight: 500;
	text-align: center;
	margin-top: 25px;
	font-size: 24px;
	@include breakpoint(lg) {
		font-size: 30px;
	}
}

.slick-dots {
	display: flex;
	justify-content: center;
	list-style: none;
	margin-top: 50px;
	@include breakpoint(xl) {
		margin-top: 100px;
	}
	li {
		margin: 0 5px;
		button {
			font-size: 0;
			padding: 0;
			width: 21px;
			height: 21px;
			border-radius: 100%;
			background: $blue-dark;
			border: 4px solid transparent;
			transition: .3s;
		}
		&.slick-active {
			button {
				background: transparent;
				border-color: $blue-dark;
			}
		}
	}

}


///

.news-item {
	&__img {
		height: 180px;
		display: block;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			filter: brightness(100%) saturate(0%) sepia(19%) saturate(180%) hue-rotate(131deg) brightness(100%);
			opacity: .76;

			transition: .3s;
		}
	}
	.heading-base {
		margin-bottom: 7px;
	}

	&__main {
		padding-top: 28px;
	}
	&__info {
		margin-bottom: 10px;
	}
	&__more {
		font-weight: 500;
		color: $blue-dark;
		&:hover {
			text-decoration: none;
			color: $pink;
		}
	}
	p {
		margin-bottom: 9px;
	}

	&:hover {
		.news-item {
			&__img {
				img {
					opacity: 1;
					filter: none;
				}
			}
		}
	}

	&--catalog {
		.news-item {
			&__img {
				height: 170px;
				@include breakpoint(md) {
					height: 240px;
				}
			}
			&__main {
				@include breakpointMax(md) {
					padding-top: 15px;					
				}
			}
		}
	}
	
	&--people {
		.news-item {
			&__img {
				img {
					opacity: 1;
					filter: none;
				}
			}
		}
		&:hover {
			.news-item {
				&__img {
					img {
						filter: brightness(100%) saturate(0%) sepia(19%) saturate(180%) hue-rotate(131deg) brightness(100%);
						opacity: .76;
					}
				}
			}
		}
	}

	&--theme-pink {
		.news-item {
			&__more {
				color: $pink;
			}
		}
	}
	&--theme-aqua {
		.news-item {
			&__more {
				color: $aqua;
			}
		}
	}
}

.slider-prev, .slider-next {
	cursor: pointer;
	width: 42px;
	height: 42px;
	border: 2px solid #000;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0 30px;
	transition: .3s;
	svg {
		stroke-width: 2px;
		stroke: #000;
		fill: none;
		width: 24px;
		transition: .3s;
	}
	&:hover {
		background: $black;
		svg {
			stroke: $white;
		}
	}

	&--theme-pink {
		border-color: $pink;
		svg {
			stroke: $pink;
		}
		&:hover {
			background: $pink;
			svg {
				stroke: $white;
			}
		}
	}
}

.slider-nav-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 25px;
}

.news-row {
	margin-left: -10px;
	margin-right: -10px;
	.slick-slide {
		padding-left: 10px;
		padding-right: 10px;
	}
}

///

.accreditations-wrapper {
	.slider-nav-wrapper {
		margin-top: 30px;
		@include breakpoint(md) {
			margin-top: 50px;
		}
		@include breakpoint(lg) {
			margin-top: 90px;
		}
	}
}

.accreditations-row {
	@include breakpoint(lg) {
		padding-left: 55px;
		padding-right: 55px;
	}
	.slick-track {
		display: flex;
		align-items: center;
	}
}

.accreditations-item {
	text-align: center;
	min-height: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

///

.breadcrumbs {
	margin-bottom: 15px;
	a {
		font-size: 16.5px;
	}
	&--theme-dark {
		a {
			color: $blue-dark;
		}
	}
}

.intro-desc {
	padding-top: 20px;
	@include breakpoint(lg) {
		padding-top: 40px;
	}
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}


///

.item-info {
	text-align: center;
	&__img {
		line-height: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 250px;
		margin-bottom: 25px;
		@include breakpoint(lg) {
			margin-bottom: 55px;
			height: 290px;
		}
	}
	&__desc {

	}
	.heading-base {
		margin-bottom: 8px;
	}
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.row-info-items {
	&>.col {
		@include breakpointMax(md) {
			&:not(:last-child) {
				margin-bottom: 40px;
			}			
		}
		@include breakpoint(xl) {
			padding-left: 90px;
			padding-right: 90px;
		}
	}
}


///

.subheading {
	font-size: 20px;
	line-height: 30px;
	&:not(:last-child) {
		margin-bottom: 30px;
		@include breakpoint(lg) {
			margin-bottom: 45px;
		}
	}
}

.list-contacts {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -15px;
	@include breakpoint(md) {
		justify-content: center;
	}
	&>li {
		display: flex;
		&>a {
			line-height: 1;
			color: $white;
			font-weight: 500;
			display: inline-flex;
			align-items: center;
			text-align: left;
			&:hover {
				text-decoration: none;
			}
		}
	}

	&.flex-column {
		margin-bottom: 0;
	}

	.wrapper-circle-icon {
		margin-right: 15px;
		flex-shrink: 0;
	}

	&--theme-pink {
		.wrapper-circle-icon {
			background: $pink;
			svg {
				fill: $white;
			}
		}
	}
	
	&--size-normal {
		&>li {
			margin: 0 10px 15px;
			@include breakpoint(md) {
				margin: 0 25px 15px;
			}
			&>a {
				font-size: 18px;
				@include breakpoint(md) {
					font-size: 20px;
				}
			}
		}
	}
	&--size-medium {
		&.flex-column {
			&>li {
				margin: 0;
				&:not(:last-child) {
					margin: 0 0 13px;
				}
			}
		}
		.wrapper-circle-icon {
			width: 38px;
			height: 38px;
		}
	}
	&--size-large {
		.wrapper-circle-icon {
			width: 45px;
			height: 45px;
			@include breakpoint(lg) {
				width: 75px;
				height: 75px;
			}
		}
		&.flex-column {
			&>li {
				margin: 0;
				&:not(:last-child) {
					margin: 0 0 15px;
					@include breakpoint(lg) {
						margin: 0 0 40px;
					}
				}
			}
		}
		&>li {
			
			&>a {
				line-height: 1;
				font-size: 26px;
				@include breakpoint(lg) {
					font-size: 33px;
				}
			}
		}
	}
	&--mod-offsets-large {
		&.flex-column {
			&>li {
				margin: 0;
				&:not(:last-child) {
					margin: 0 0 40px;
				}
			}
		}
	}
	&--mod-offsets-medium {
		&.flex-column {
			&>li {
				margin: 0;
				&:not(:last-child) {
					margin: 0 0 15px;
					@include breakpoint(lg) {
						margin: 0 0 30px;
					}
				}
			}
		}
	}
}

.row-contacts {
	&>.col {
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

.wrapper-circle-icon {
	width: 46px;
	height: 46px;
	border-radius: 100%;
	background: $white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	svg {
		width: 100%;
		height: 100%;
		max-width: 60%;
		max-height: 60%;
		fill: $pink;
	}
}

///

.list-content {
	list-style: none;
	text-align: center;
	&>li {
		@include breakpointMax(lg) {
			margin-bottom: 20px;
			
		}
		&:not(:last-child) {
			@include breakpoint(lg) {
				margin-bottom: 45px;
			}
		}
		&>a {
			color: $white;
			font-size: 20px;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

///

.text-info {
	&:not(:last-child) {
		margin-bottom: 25px;
	}
	.heading-base {
		margin-bottom: 10px;
	}
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

///

.article-col-side {
	@include breakpointMax(lg) {
		margin-bottom: 30px;
		
	}
}

.article-head-img {
	height: 250px;
	@include breakpoint(md) {
		height: 385px;
	}
	@include breakpointMax(lg) {
		margin-left: -15px;
		margin-right: -15px;
		
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.article-wrapper {
	ul {
		list-style-position: inside;
		list-style: none;
		&:not(:last-child) {
			margin-bottom: 25px;
		}
		&>li {
			position: relative;
			padding-left: 15px;
			margin-bottom: 14px;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 12px;
				width: 4px;
				height: 4px;
				background: $black;
				border-radius: 100%;
			}
		}
	}
	.heading-base {
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 25px;
	}

	&--opened {
		padding-top: 50px;
		padding-bottom: 50px;
		ul {
			&:not(:last-child) {
				margin-bottom: 25px;
			}
			&>li {
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}
		}
	}
}

///

.row-menu-list {
	margin-bottom: -30px;
	&>.col {
		margin-bottom: 30px;
	}
}

.menu-list {
	&:not(:first-child) {
		margin-top: 20px;
		@include breakpoint(md) {
			margin-top: 30px;
		}
	}
	list-style: none;
	&>li {
		position: relative;
		margin-left: 30px;
		@include breakpoint(lg) {
			margin-left: 0;
		}
		&:not(:last-child) {
			margin-bottom: 3px;
		}
		&>a {
			font-size: 20px;
			color: $blue-dark;
			font-weight: 500;
		}
		&:before {
			content: "";
			position: absolute;
			left: -30px;
			top: 3px;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			background: $white;
			opacity: 0;
			visibility: hidden;
		}
		&.active {
			&:before {
				visibility: visible;
				opacity: 1;
			}
			&>a {
				color: $white;
			}
		}
	}
}

///

.news-item-large {
	.heading-base {
		margin-bottom: 10px;
		@include breakpoint(md) {
			margin-bottom: 22px;
		}
		&:not(:first-child) {
			margin-top: 10px;
			@include breakpoint(md) {
				margin-top: 15px;
			}
		}
	}
	p {
		margin-bottom: 12px;
	}
	&__img {
		max-height: 300px;
		@include breakpoint(md) {
			max-height: 430px;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__desc {
		padding-top: 15px;
		@include breakpoint(md) {
			padding-top: 4px;
		}
	}
}

.news-row-catalog {
	margin-bottom: -30px;
	&.row {
		margin-left: -11px;
		margin-right: -11px;
		&>.col {
			margin-bottom: 30px;
			padding-left: 11px;
			padding-right: 11px;
		}
	}
	
}

.load-more-wrapper {
	margin-top: 40px;
	@include breakpoint(lg) {
		margin-top: 70px;
	}
}

.link-load-more {
	line-height: 1;
	display: inline-flex;
	align-items: center;
	font-size: 24px;
	@include breakpoint(lg) {
		font-size: 30px;
	}
	&__icon {
		border-radius: 100%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $pink;

		width: 35px;
		height: 35px;
		@include breakpoint(lg) {
			width: 41px;
			height: 41px;
		}
		svg {
			width: 25px;
			height: 25px;
			fill: none;
			stroke: $pink;
		}
		&:not(:first-child) {
			margin-left: 15px;
		}
	}
	&:hover {
		text-decoration: none;
	}

	&--theme-aqua {
		color: $aqua;
		.link-load-more {
			&__icon {
				border: 2px solid $aqua;
				svg {
					stroke: $aqua;
				}
			}
		}
	}
}


///

.list-letters {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	@include breakpoint(lg) {
		margin-bottom: 45px;
	}
	&:not(:first-child) {
		margin-top: 30px;
	}
	&>li {
		position: relative;
		margin-bottom: 15px;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			width: 50px;
			height: 50px;
			transform: translate(-50%, -50%);
			border: 2px solid $white;
			border-radius: 100%;
			opacity: 0;
			visibility: hidden;
			transition: .3s;
		}
		&:not(:last-child) {
			margin-right: 21px;
		}
		&.current {
			&:before {
				opacity: 1;
				visibility: visible;
			}
			&>a {
				color: $white;
			}
		}
		&>a {
			font-size: 24px;
			line-height: 1;
			font-weight: 500;
			text-decoration: none;
			color: $blue-dark;
		}
	}
}

///

.personal-info {
	padding-bottom: 50px;
	@include breakpoint(lg) {
		padding-left: 50px;
	}
	@include breakpoint(xl) {
		padding-left: 100px;
	}
	.breadcrumbs {
		margin-bottom: 25px;
	}
	.heading-base {
		margin-bottom: 20px;
	}
}

.personal-photo {
	line-height: 0;
	@include breakpointMax(lg) {
		height: 300px;
		margin-bottom: 15px;
		display: flex;
		justify-content: center;
		img {
			max-width: 400px;
		}
	}
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.list-tags {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	&>li {
		margin: 0 15px 15px 0;
		&>a {
			display: inline-flex;
			background: #525D6B;
			color: $blue-dark;
			padding: 4px 20px;
			font-size: 20px;
			font-weight: 500;
			&:hover {
				text-decoration: none;
				background: lighten(#525D6B, 5%);
			}
		}
	}
}

.team-role {
	font-size: 20px;
	font-weight: 500;
}

.spec-areas {
	&:not(:last-child) {
		margin-bottom: 30px;
		@include breakpoint(lg) {
			margin-bottom: 50px;
		}
	}
	&:not(:first-child) {
		margin-top: 15px;
		@include breakpoint(lg) {
			margin-top: 30px;
		}
	}
	&__title {
		font-weight: 300;
		margin-bottom: 20px;
	}
}

.article-nav {
	&.row {
		@include breakpoint(lg) {
			margin-left: 0;
			margin-right: 0;
		}
		&>.col {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.contact-check {
	&:not(:first-child) {
		margin-top: 40px;
	}
	&:not(:last-child) {
		margin-bottom: 40px;
	}
}

.form-contact {
	.btn-base {
		min-width: 170px;
	}
}


.row-vacanices {
	margin-top: 50px;
	margin-bottom: -30px;
	@include breakpoint(lg) {
		margin-bottom: -50px;
	}
}

.item-vacancie {
	border-top: 2px solid $black;
	margin-bottom: 30px;
	padding-top: 30px;
	@include breakpoint(md) {
		padding-top: 40px;
		margin-bottom: 50px;
	}
	.heading-base {
		margin-bottom: 10px;
		@include breakpointMax(xl) {
			font-size: 30px;
		}
	}
	p {
		margin-bottom: 10px;
	}
}

.schedule-time {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 5px;
}

.intro-img {
	img {
		@include breakpointMax(lg) {
			width: 100%;
			max-width: 300px;
		}
	}
}

.col-about-img {
	img {
		@include breakpointMax(xl) {
			width: 100%;
			max-width: 400px;	
		}
	}
}









