/* ==========================================================================
// Fonts
   ========================================================================== */
@font-face {
    font-family: 'Matter';
    src: url('../fonts/Matter-Regular.eot');
    src: local('Matter Regular'), local('Matter-Regular'),
        url('../fonts/Matter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Matter-Regular.woff2') format('woff2'),
        url('../fonts/Matter-Regular.woff') format('woff'),
        url('../fonts/Matter-Regular.ttf') format('truetype'),
        url('../fonts/Matter-Regular.svg#Matter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Light.eot');
    src: local('Euclid Circular A Light'), local('EuclidCircularA-Light'),
        url('../fonts/EuclidCircularA-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EuclidCircularA-Light.woff2') format('woff2'),
        url('../fonts/EuclidCircularA-Light.woff') format('woff'),
        url('../fonts/EuclidCircularA-Light.ttf') format('truetype'),
        url('../fonts/EuclidCircularA-Light.svg#EuclidCircularA-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Medium.eot');
    src: local('Euclid Circular A Medium'), local('EuclidCircularA-Medium'),
        url('../fonts/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EuclidCircularA-Medium.woff2') format('woff2'),
        url('../fonts/EuclidCircularA-Medium.woff') format('woff'),
        url('../fonts/EuclidCircularA-Medium.ttf') format('truetype'),
        url('../fonts/EuclidCircularA-Medium.svg#EuclidCircularA-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../fonts/EuclidCircularA-Regular.eot');
    src: local('Euclid Circular A'), local('EuclidCircularA-Regular'),
        url('../fonts/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EuclidCircularA-Regular.woff2') format('woff2'),
        url('../fonts/EuclidCircularA-Regular.woff') format('woff'),
        url('../fonts/EuclidCircularA-Regular.ttf') format('truetype'),
        url('../fonts/EuclidCircularA-Regular.svg#EuclidCircularA-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Burgess Trial';
    src: url('../fonts/BurgessTrial-Regular.eot');
    src: local('Burgess Trial Regular'), local('BurgessTrial-Regular'),
        url('../fonts/BurgessTrial-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BurgessTrial-Regular.woff2') format('woff2'),
        url('../fonts/BurgessTrial-Regular.woff') format('woff'),
        url('../fonts/BurgessTrial-Regular.ttf') format('truetype'),
        url('../fonts/BurgessTrial-Regular.svg#BurgessTrial-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
