.d-flex {
	display: flex;
}

//

.noselect {
    user-select: none;
}

.slick-slide {
	user-select: text;
}

// ------

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-nowrap {
	white-space: nowrap;
}


// ------

.text-green {
	color: #159E24;
}

.text-red {
	color: #8A0502;
}

.text-yellow {
	color: #83653D;
}

.text-grey {
	color: #616781;
}

// ------

.row-offset-10 {
	margin: 0 -5px;
	& > [class^="col-"] {
		padding: 0 5px;
	}
}


// -----

.is-hidden {
	display: none;
}

.is-sm-visible {
	@include breakpointMax(sm) {
		display: none;
	}
}

.is-md-visible {
	@include breakpointMax(md) {
		display: none;
	}
}

.is-lg-visible {
	@include breakpointMax(lg) {
		display: none;
	}
}

.is-xl-visible {
	@include breakpointMax(xl) {
		display: none;
	}
}

// -----

.is-xs-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-sm-hidden {
	@include breakpoint(sm) {
		display: none;
	}
}

.is-md-hidden {
	@include breakpoint(md) {
		display: none;
	}
}

.is-lg-hidden {
	@include breakpoint(lg) {
		display: none;
	}
}

.is-xl-hidden {
	@include breakpoint(xl) {
		display: none;
	}
}


// -----

.is-menu-active {
	overflow: hidden;
}

// -----

.pixelated {
	image-rendering:optimizeSpeed;
	image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering:optimize-contrast;
	image-rendering:crisp-edges;
}

///

.col-lg-2-5 {
	@include breakpoint(lg) {
		flex: 0 0 20.83%;
		max-width: 20.83%;
	}
}

///

.animate__fadeIn {
	animation-name: fadeIn;
	visibility: hidden;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}

///

.mb-40 {
	margin-bottom: 40px;
}

.mb-xl-0 {
	@include breakpoint(xl) {
		margin-bottom: 0;
	}
}


