/* ==========================================================================
// Header
   ========================================================================== */

.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding-top: 23px;
	padding-bottom: 23px;
	z-index: 50;
	// background: $blue-dark;
	&>.container {
		max-width: 1350px;
		position: relative;
		z-index: 100;
	}
}

.header-menu {
	list-style: none;
	display: flex;
	&>li {
		&:not(:last-child) {
			margin-right: 30px;
			@include breakpoint(xl) {
				margin-right: 45px;
			}
		}
		&>a {
			color: $white;
			font-family: $fontSecond;
			font-size: 16.5px;
			display: inline-flex;
			align-items: center;
			&:hover {
				text-decoration: none;
				color: $pink;
				.angle-down {
					stroke: $pink;
				}
			}
			.angle-down {
				margin-left: 7px;
				margin-top: 3px;
				width: 13px;
				height: 10px;
				fill: none;
				stroke-width: 2px;
				stroke: $white;
				transition: .3s;
			}
		}
	}
}

.header-logo {
	display: inline-block;
	line-height: 0;
	width: 165px;
}

.logo--black {
	display: none;
}

.header-dropdown {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: $white;
	box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
	
	opacity: 0;
	visibility: hidden;

	transition: .7s;
	
	
	padding: 50px 0 ;
	@include breakpointMax(lg) {
		position: fixed;
		height: 100%;
		overflow-y: auto;
		.btn-mob-menu {
			position: absolute;
			right: 15px;
			top: 20px;
		}
	}
	@include breakpoint(lg) {
		padding: 130px 0 100px;
	}

	&.active {
		opacity: 1;
		visibility: visible;
		@include breakpointMax(lg) {
			z-index: 250;
		}
	}

	.menu-list {
		& > li {
			&.active {
				&>a {
					color: $pink;
				}
				&:before {
					background: $pink;
				}
			}
		}
	}
	.btn-mob-menu span, .btn-mob-menu:before, .btn-mob-menu:after {
		background: $blue-dark;
	}
}

body {
	&.header-dropdown-active {
		.header-menu {
			&>li {
				&>a {
					color: $blue-dark;
					.angle-down {
						stroke: $blue-dark;
					}
				}
			}
		}
		.logo--white {
			display: none;
		}
		.logo--black {
			display: block;
		}
	}
}

.mobile-menu {
	@include breakpointMax(lg) {
		opacity: 0;
		visibility: hidden;
		transition: .25s;
		position: fixed;
		background: $white;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 200;

		justify-content: flex-start;
		align-items: center;
		text-align: center;
		padding-top: 60px;


		&.active {
			opacity: 1;
			visibility: visible;
		}

		.btn-mob-menu {
			position: absolute;
			right: 15px;
			top: 20px;
		}

		.header-menu {
			flex-direction: column;
			&>li {
				margin: 0 0 10px;
				&>a {
					color: $blue-dark;
					.angle-down {
						stroke: $blue-dark;
					}
				}
			}
		}


	}
}


