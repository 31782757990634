/* ==========================================================================
// Typography
   ========================================================================== */


body {
    font: 400 17px/26px $fontBase;
}


* {
    outline: none;
}


// h1, h2, h3, h4, h5, h6 {
//     line-height: 1.333;
//     font-weight: 400;
//     margin: 0 0 20px;
// }

h1 {
}

h2 {
    font-weight: 300;
    line-height: 31px;
    margin: 0 0 25px;
    font-size: 24px;
    @include breakpoint(md) {
        font-size: 26px;
    }
}

h3 {
    color: $pink;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 15px;
    @include breakpoint(md) {
        font-size: 30px;
    }
    @include breakpoint(lg) {
        margin: 0 0 30px;
    }
    &:not(:first-child) {
        margin-top: 30px;
        @include breakpoint(lg) {
            margin-top: 40px;
        }
    }
}

h4 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
    &:not(:last-child) {
        margin: 0 0 5px;
    }
}
// h3 {
//     font-size: 20px;
// }

// h4 {
//     font-size: 18px;
// }

// h5 {
//     font-size: 16px;
// }

// h6 {
//    font-size: 14px;
// }

p, figure, em, blockquote {
    margin: 0 0 25px;
    display: block;
}


img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;

}


blockquote {
    display: block;

    margin: 0 auto 35px;
    position: relative;
    max-width: 480px;

    font-family: $fontThird;
    color: $pink;
    
    padding-left: 30px;
    font-size: 22px;
    line-height: 28px;
    @include breakpoint(md) {
        font-size: 24px;
        line-height: 34px;
        padding-left: 0;
    }

    p {
        margin: 0 0 10px;
        &:last-child {
            margin: 0;
        }
    }

    &:before {
        content: "“";
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1;
        font-family: $fontThird;
        font-size: 60px;
        @include breakpoint(md) {
            font-size: 108px;
            left: -60px;
        }
    }
}

em {
    display: block;
    font-size: 18px;
    line-height: 1.666;
    font-weight: 400;
    font-style: normal;
}

cite {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
}

b, strong {
    font-weight: 700;
}

dl {
    margin: 30px 0;
}

dt {
    display: block;
    margin: 0 0 13px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: #000;
}
dd {
    display: block;
    margin: 0 0 20px;
}


hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 24px 0;
    clear: both;
}



a {
    transition: color .3s;
    color: $pink;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

mark {
    background: none;
    color: #347D3B;
}

video {
    max-width: 100%;
}

textarea {
    resize: none;
    overflow: hidden;
}

.heading-main {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 1.2;
    @include breakpoint(md) {
        font-size: 50px;
    }
    @include breakpoint(lg) {
        line-height: 1;
        font-size: 70px;
    }
    span {
        font-weight: 400;
        font-size: 38px;
        color: $pink;
        font-family: $fontThird;
        @include breakpoint(md) {
            font-size: 52px;
        }
        @include breakpoint(lg) {
            font-size: 68px;
        }
    }
}

.heading {
    line-height: 1.1;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    @include breakpoint(lg) {
        font-size: 30px;
    }
    &--offset-normal {
        margin-bottom: 30px;
        @include breakpoint(lg) {
            margin-bottom: 50px;
        }
    }
    &--offset-large {
        margin-bottom: 30px;
        @include breakpoint(lg) {
            margin-bottom: 70px;
        }
    }
}

.heading-base {
	font-family: $fontThird;
	line-height: 1;
    &--size-normal {
        font-size: 32px;
        @include breakpoint(lg) {
            font-size: 40px;
        }
    }
    &--size-medium {
        font-size: 32px;
        @include breakpoint(lg) {
            font-size: 40px;
        }
        @include breakpoint(xl) {
            font-size: 50px;
        }
    }
    &--size-large {
        font-size: 68px;
    }
    &--mod-lh {
        line-height: 1.2;
    }

    &--intro {
        color: $pink;
        font-size: 40px;
        @include breakpoint(lg) {
            font-size: 68px;
        }
    }
}

.heading-article {
    font-weight: 500;
    line-height: 1.2;
    font-size: 24px;
    margin-bottom: 20px;
    @include breakpoint(lg) {
        font-size: 30px;
        margin-bottom: 30px;
    }
}

.notice {
    color: $pink;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}






