/* ==========================================================================
// Buttons
   ========================================================================== */

.btn {
	text-decoration: none;
	background: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: .25s;
	text-align: center;
	max-width: 100%;
	cursor: pointer;
	font-family: $fontBase;
	&:hover {
		text-decoration: none;
	}
	&:active {
		transition: none;
	}

	&-base {
		min-height: 32px;
		padding: 5px 30px;
		font-size: 20px;
		line-height: 1;
		font-weight: 500;
		&--theme-dark {
			background: $blue-dark;
			color: $pink;
			&:hover {
				background: lighten($blue-dark, 7%);	
			}
		}
		&--theme-pink {
			background: $pink;
			color: $blue-dark;
			&:hover {
				background: darken($pink, 7%);	
			}
		}
		&--theme-aqua {
			background: $aqua;
			color: $blue-dark;
			&:hover {
				background: darken($aqua, 7%);	
			}
		}

		&--size-medium {
			height: 48px;
			padding-left: 30px;
			padding-right: 30px;
			@include breakpoint(lg) {
				padding-left: 60px;
				padding-right: 60px;
			}
		}

		&--color-white {
			color: $white;
		}
	}

	&-article-nav {
		height: 60px;
		line-height: 1;
		font-weight: 500;
		flex-grow: 1;
		width: 100%;
		color: $blue-dark;
		font-size: 20px;
		@include breakpoint(lg) {
			font-size: 30px;
			height: 123px;
		}
		&:hover {
			background: $aqua-light;
		}
	}

	&-mob-menu {
		position: relative;
		width: 28px;
		height: 18px;
		transition: 0s;
		z-index: 205;
		& span,
		&:before,
		&:after{
			content: "";
			display: block;
			width: 28px;
			height: 3px;
			background-color: $white;
			position: absolute;
			left: 0;
			transition: all 300ms ease-in-out;
			border-radius: 2px;
		}

		&:before{
			top:0px;
		}
		& span{
			top:8px;
			opacity:1;
		}
		&:after{
			top:16px;
		}

		&.active {
			span {
				opacity:0;
				top:8px;
				width: 25px;
				transform:rotate(45deg);
				background: $black;
			}
			&:before {
				width:25px;
				top:8px;
				left: 0px;
				transform:rotate(45deg);
				background: $black;
			}
			&:after {
				width:25px;
				top:8px;
				left: 0px;
				transform:rotate(-45deg);
				background: $black;
			}
		}
	}



	&--fullwidth {
		width: 100%;
	}


}
