/* ==========================================================================
// Icons
   ========================================================================== */

[class^="icon-"], [class*=" icon-"] {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	fill: currentColor;
	fill-rule: evenodd;
	max-height: 100%;
	max-width: 100%;
	flex-shrink: 0;
}
