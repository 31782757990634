// .icon {
// 	display: inline-block;
// 	width: 1em;
// 	height: 1em;
// 	fill: currentColor;
// }

.angle-down {
	display: inline-block;
	vertical-align: middle;
	width: 13.79px;
	height: 7.96px;
}
.arrow-down {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.arrow-left {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.arrow-right {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
}
.icon-check {
	display: inline-block;
	vertical-align: middle;
	width: 80.59px;
	height: 61.16px;
}
.icon-linkedin {
	display: inline-block;
	vertical-align: middle;
	width: 20.22px;
	height: 19.93px;
}
.icon-mail {
	display: inline-block;
	vertical-align: middle;
	width: 27.77px;
	height: 19.99px;
}
.icon-tel {
	display: inline-block;
	vertical-align: middle;
	width: 27.85px;
	height: 28.11px;
}

