/* ==========================================================================
// Footer
   ========================================================================== */

.footer {
	padding-top: 50px;
	padding-bottom: 25px;
	@include breakpoint(lg) {
		padding-bottom: 50px;
	}
	@include breakpoint(xl) {
		padding-top: 103px;
		padding-bottom: 160px;
	}
	&>.container {
		@include breakpoint(lg) {
			padding-left: 70px;
		}
	}
	.col {
		@include breakpointMax(lg) {
			margin-bottom: 25px;
		}
	}
}


.footer-heading {
	color: $pink;
	font-weight: 500;
	font-family: $fontSecond;
	line-height: 1;
	font-size: 16px;
	margin-bottom: 3px;
}


.footer-logo {
	width: 161px;
}

.footer-menu {
	list-style: none;
	line-height: 1;
	&>li {
		&:not(:last-child) {
			margin-bottom: 1px;
		}
		&>a {
			font-size: 16px;
			font-family: $fontSecond;
			color: $blue-dark;
		}
	}
}

.footer-contacts {
	color: $pink;
	font-size: 16px;
	line-height: 19.2px;
	margin-top: 15px;
}

.footer-sec-menu {
	line-height: 13px;
	list-style: none;
	&:not(:last-child) {
		margin-bottom: 15px;
	}
	&>li {
		&>a {
			color: $pink;
			font-size: 12px;
		}
	}
}

.footer-more {
	display: flex;
	flex-direction: column;
}

.footer-copyright {
	color: $pink;
	font-size: 12px;
	line-height: 1;
	margin-top: auto;
}


