/* ==========================================================================
// Figures
   ========================================================================== */

.ui-figure {
	overflow: hidden;
	margin: 0 0 20px;
	img {
		display: block;
	}

}

